import React from 'react';
import './index.css'; // Assume styles specific to header are in Header.css
import logo from './imgs/CONNCEPTS-LOGO-test.png'; // Make sure the logo image is in your src folder

function Header() {
  return (
    <header className="hero-section">
      <a href="/" style={{ display: 'block', color: 'inherit', textDecoration: 'none' }}>
        <img src={logo} alt="Conncepts" />
        <h1 className="bebas-neue-regular" hidden>Conncepts ERP Consulting</h1>
        <p>Innovative ERP Solutions for Modern Business</p>
        <span>Tampa, Florida</span>
      </a>
    </header>
    
  );
}

export default Header;
