import React from 'react';
import './index.css'; // Assume styles specific to services are in Services.css
import whymeImage from './imgs/whyme.webp'; // Make sure the service images are in your src folder
import analyticsImage from './imgs/analytics.webp';
import integrationImage from './imgs/integration.webp';

function Customizations() {
  return ( 
   <section>
   <article className="service2header">
  <p className="integrations">Customization can take your ERP to the next level by allowing you to laser focus the look and feel of your ERP system. From adding fields to brand new logic, Epicor ERP offers tons of ways to extend and personalize your product. And of course to keep your system running effectively and up to date, we offer all the support services you need.</p>
      </article>

    <section className="services">
   

  <article className="service2">
  <h2>Customizations</h2>
      <p>WinForms and Kinetic form customization</p>
      <p>Custom Report Writing</p>
      <p>Custom Dashboard Creation</p>
      <p>Custom BPM Creation</p>
      <p>Custom BAQ and SQL query Creation</p>
      <p>Custom Process Development</p>   
      <p>Custom Bartender Labels</p>   
  </article>
  
  <article className="service2">
    <h2>Support</h2>
 
      <p>Server and Database Upgrades</p>
      <p>Index and Backup maintenance</p>
      <p>Performance Tuning</p>
      <p>Menu\Epicor Security</p>
      <p>Customization Support and Training</p>
      <p>Emergency Fixes and Support!</p>
      <p>Client Deployment</p>  
      <p>EFT\SEPA\ACH Payments</p>    
  </article>

  <article className="service2">
    <h2>Smart Friends</h2>
    
<p>We all need a smart friend, and I have one of the best with years of experience in Epicor ERP in Michael Thompson at C6 Consulting. He has been a great resource for me and my clients.</p>

C6 excels at business process improvement, project planning, and ERP setup and configuration. They are a great resource for any company looking to improve their business processes and systems.

<p><a href="https://www.c6-consulting.com">www.c6-consulting.com</a></p>
  </article>
</section>
</section>
  );
}

export default Customizations;
