import React from 'react';
//  <a href="/utilities"><button>Free Utilities!</button></a>

function About() {
  return (


    <section className="about">

      <h2>Discover More</h2>
      <p>We are a leader in creative solutions!</p>
      <a href="/contact"><button>Contact Us</button></a>
      
    </section>
  );
}

export default About;
