import React from "react";
import "./index.css"; // Assume styles specific to services are in Services.css
import Rates from "./rates";
import RateDiscounts from "./ratediscounts";
import whymeImage from "./imgs/whyme.webp"; // Make sure the service images are in your src folder
import analyticsImage from "./imgs/analytics.webp";
import integrationImage from "./imgs/integration.webp";

function Contact() {
  return (
    <section>
      <p class="integrations">
        Please contact me to discover how I can help your business!
      </p>

      <section class="services">
        <article class="service2header" style={{ padding: "10px" }}></article>
        <article>
          <br />
          <h2>Rates</h2>
          <hr />
          <Rates />
          <hr />
          <br />
          Discounts!
          <RateDiscounts />
          {/*<p>Basic Hourly $200/hr as available</p>
<p>BiWeekly Retainer ($400) $170/hr </p>
<p>Monthly Retainer ($600) $150/hr</p>*/}
        </article>

        <article class="service2">
          <h2>Contact Info</h2>

          <p>Conncepts Consulting</p>
          <p>Spring Hill, FL</p>

          <br />
        </article>

        <article class="service2">
          <h2>Email</h2>
          <p>sales@conncepts.pro</p>
        </article>

        <article class="service2">
          <h2>Phone</h2>

          <p>813-336-1662</p>
        </article>
      </section>
    </section>
  );
}

export default Contact;
