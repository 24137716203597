import React from 'react';
import './index.css'; // Assume styles specific to services are in Services.css
import whymeImage from './imgs/whyme.webp'; // Make sure the service images are in your src folder
import analyticsImage from './imgs/analytics.webp';
import integrationImage from './imgs/integration.webp';

function Services() {
  return (
    <section className="services">
      <article className="service">
       {/*  <h2>Why Choose Me?</h2>*/}
        <a href="/why-me"><button>Why Choose Me</button></a>
        <img src={whymeImage} alt="Why Choose Me" />
      </article>
     
      <article class="service">
       {/*<h2>Customization & Support</h2>*/}
    <a href="/customizations"><button>Customization & Support</button></a>  
    <img src={analyticsImage} alt="Analytics Dashboard"/>    
  </article>

  <article class="service">
       {/*<h2>Integrations</h2>*/}
    <a href="/integrations"><button>Integrations</button></a>    
    <img src={integrationImage} alt="Presentation"/>  
  </article>
</section>
    
  );
}

export default Services;
