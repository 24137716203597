import React from 'react';
import './index.css'; // Assume styles specific to services are in Services.css
import whymeImage from './imgs/whyme.webp'; // Make sure the service images are in your src folder
import analyticsImage from './imgs/analytics.webp';
import integrationImage from './imgs/integration.webp';
import integrationImage2 from './imgs/integrations2.webp';

function Integrations() {
  return ( 
<section>
<div class="service2header" style={{width:'100%'}}>
          <p className="integrations">Integrations can take your ERP to the next level by allowing a third party software to push data into, or extract data out of your ERP system. Any data pushed into your ERP system should be safely executed by using Business Logic. Many times, inexperienced integrators will, based on their knowledge of SQL and the ERP schema, attempt to directly enter data using SQL but this more often than not will cause issues with your data integrity.</p>
</div>

    <section class="services">
  

  <article class="service2">
    <h2>Implemented Integrations</h2>
  
      <p>Bartender (FileDrop and Webservice)</p>
      <p>Advanced Requisitions Management (ARM)</p>
      <p>Epicor Content Management (ECM\DocStar)</p>
      <p>Epicor Intelligent Data Capture (IDC\OCR)</p>
      <p>SMART Forecasting</p>
      <p>ADP TimeKeeping</p>
      <p>Payserv HR</p>
      <p>Concept One shop</p>
      <p>Salesforce CRM</p>
      <p>Active Directory\Azure\Entra</p>
      <p>Microsoft Project</p>
      <p>Barcode and RFID readers</p>
      <p>Artificial Intelligence (AI)</p>  
    
  </article>
  
  <article class="service2">
    <h2>Tools</h2>
  
      <p>Custom C# Development</p>
      <p>Automation Studio</p>
      <p>Workato</p>
      <p>Epicor SDK</p>
      <p>Service Connect</p>
      <p>EFT\SEPA\ACH\PositivePay Electronic Payments</p>
    
  </article>

  <article class="service2">
    <h2>Additional Skills</h2>
  <p>Bartender Label Design</p>
  <p>SSRS and Crysal Reports</p>
  <p>Image Editing and Icon Creation</p>
  <p>Video Capture and Video Editing</p>
  <p>Sound Design and Editing</p>

  </article>
</section>
</section>
    
  );
}

export default Integrations;
