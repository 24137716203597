import React from "react";
import "./index.css"; // Your main CSS
function Rates() {
  return (
    <table class="styled-table">
      <thead>
        <tr>
          {/* <th>Agreement</th>*/}
          <th>Hourly Rate</th>
          <th>Retainer</th>
          <th>Retainer $</th>
          <th>Typical 1st Availability</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          {/* <td>Basic</td>*/}
          <td>$220</td>
          <td>None</td>
          <td>$0</td>
          <td>~2 weeks</td>
        </tr>
        <tr>
          {/*  <td>BiWeekly</td>*/}
          <td>$190</td>
          <td>BiWeekly</td>
          <td>$400</td>
          <td>~1 week</td>
        </tr>
        <tr class="active-row">
          {/*   <td>Monthly</td>*/}
          <td>$150</td>
          <td>Monthly</td>
          <td>$600</td>
          <td>~2 days</td>
        </tr>
      </tbody>
    </table>
  );
}

export default Rates;
