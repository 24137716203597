import React from 'react';
import './index.css'; // Assume styles specific to services are in Services.css
import whymeImage from './imgs/whyme.webp'; // Make sure the service images are in your src folder
import analyticsImage from './imgs/analytics.webp';
import integrationImage from './imgs/integration.webp';
import myFaceImage from './imgs/headshot-transformed.jpeg';

function WhyMe() {
  return ( 
    <section class="services">
    <article class="service2header">
      <h2>Why Choose Me for Your Business?<img src={myFaceImage} className='myFace'/></h2>
    </article>

 <article class="service2header">
  <p>
    With <strong>9 years of dedicated experience</strong> in working with <em>Epicor ERP</em>, 
    I bring a wealth of knowledge and expertise to the table. My journey has allowed me to collaborate 
    with <strong>over 50 clients</strong>, both as a valuable team member of a <em>Platinum Partner </em> 
     and as an independent consultant. This diverse exposure has equipped me with a profound understanding 
    of a wide array of challenges and solutions within the ERP landscape.
  </p>
  <br/>
  <p>
    My experience spans across various industries, enabling me to offer tailored, effective solutions 
    that drive efficiency and growth. Whether you're facing complex issues or seeking to optimize your 
    ERP system, I am here to help.
  </p>
  <p>
    <strong>Contact me today</strong> to discuss your needs and how I can assist in transforming 
    your business operations with Epicor ERP.
  </p>
 <br/>
  <p><a hidden = "hidden" href="/contact">Get in Touch</a></p>
 </article>
</section>


    
  );
}

export default WhyMe
