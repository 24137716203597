import React from 'react';
//import './utilities.css'; // Assume styles specific to services are in Services.css

import cfgImage from './imgs/configimg.png';
import htmlImage from './imgs/htmlreportimg.png';

import logAnalyzerZip from './files/MRPLogAnalyzer.zip'

function Utilities() {
  return ( 
<section>
<div class="service2header" style={{width:'100%'}}>
          <p className="integrations">MRP Log Aggregator and Purge Utility</p>
</div>

    <section class="services">
  

  <article class="utility">
    
    <h1>Aggregate errors/exceptions from your Epicor MRP logs</h1>
    <p><strong>OnPrem only</strong> (though with enough interest I could probably make a Cloud version)</p>
    <p><strong>Bonus</strong> – purge old log files from your EpicorData folders.</p>
    <br></br>
    
    <p>If you have multiple environments and/or multiple companies, sometimes it can be a pain to review piles of very long log files looking for the needle in the haystack. This solution will take a list of log files to monitor. Each time it is ran, it will generate an HTML report of the issues. There is a navigatable Table of Contents.</p>
    <br></br>

    <h2>Resulting HTML file:</h2>
    <br></br>
    <img src={htmlImage} alt="HTML Output Example"/>
    <br></br> <br></br>

    <h2>Output:</h2>
    <p><code>Error_log.html</code>, generated in this application's folder. This overwrites with each run.</p>
    <br></br> <br></br>
    <h2>Requirements:</h2>
    
        <li>Windows</li>
        <li>.NET8 <a href="https://dotnet.microsoft.com/en-us/download/dotnet/8.0">https://dotnet.microsoft.com/en-us/download/dotnet/8.0</a></li>
    <br></br> <br></br>
    
    <h2>Configuration (see MRPLogAnalyzer.dll.config)</h2>
        <li><code>LogFilePaths</code> = Comma separated list of the log files to review</li>
        <li><code>LogDirPurgeDays</code> = how many days worth of logs to keep</li>
        <li><code>LogDirsToPurge</code> = Comma separated list of the directories to search for old .log files and purge. Leave blank string in value for no action.</li>
        <br></br>
    
    <img src={cfgImage} alt="App.Config Example"/>
    <br></br>
    <p class="note">
        <p><strong>Note:</strong> Obviously the directories/files you are trying to access must be reachable. In my examples I am using my shared "EpicorData" folders. The user account you run this with will determine what permissions (if any) are used to access the files/folders.</p>
    </p>

    <p>
      <button><a href={logAnalyzerZip}>Download MRPLogAggregator</a></button>
      <br></br>
      Unzip to desired directory. Modily config file. Run MRPLogAnalyzer.exe
    </p>
   
   <p>
    <br></br>
    <bold>No warranties. Use at your own risk!</bold>
    </p>
  </article>
  


</section>
</section>
    
  );
}

export default Utilities;
