import React from "react";
import "./index.css"; // Your main CSS
function RateDiscounts() {
  return (
    <table class="styled-table">
      <thead>
        <tr>
          {/* <th>Agreement</th>*/}
          <th>Months</th>
          <th>Retainer Discount</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          {/* <td>Basic</td>*/}
          <td>2</td>
          <td>10%</td>
        </tr>
        <tr>
          {/*  <td>BiWeekly</td>*/}
          <td>4</td>
          <td>20%</td>
        </tr>
        <tr class="active-row">
          {/*   <td>Monthly</td>*/}
          <td>6 or more</td>
          <td>40%</td>
        </tr>
      </tbody>
    </table>
  );
}

export default RateDiscounts;
