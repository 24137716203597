import React, { useEffect, useRef, useState } from 'react';

const GameWrapper = () => {
  const gameContainerRef = useRef(null);
  const iframeRef = useRef(null);

  const [iframeHeight, setIframeHeight] = useState('600px');

  const loadGame = () => {
    const iframe = iframeRef.current;
    if (!iframe) return;

    iframe.srcdoc = `
      <html>
        <head>
          <link rel="stylesheet" href="/game/css/styles.css">
          <style>
            body, html { margin: 0; padding: 0; overflow: hidden; }
            #gameContainer { width: 100%; height: 100vh; }
          </style>
        </head>
        <body>
          <div id="gameContainer"></div>
          <script>
            function initGame() {
              fetch('/game/game.html')
                .then(response => response.text())
                .then(html => {
                  document.getElementById('gameContainer').innerHTML = html;
                  const script = document.createElement('script');
                  script.src = '/game/game.js';
                  document.body.appendChild(script);
                  
                  // Notify parent when game is loaded
                  script.onload = () => {
                    window.parent.postMessage({ type: 'gameLoaded' }, '*');
                  };
                });
            }

            // Listen for resize events from the game
            window.addEventListener('resize', () => {
              window.parent.postMessage({ 
                type: 'resize', 
                height: document.body.scrollHeight 
              }, '*');
            });
          </script>
        </body>
      </html>
    `;

    iframe.onload = () => {
      iframe.contentWindow.initGame();
    };
  };



  useEffect(() => {

    const loadGameOld = async () => {
      // Load game HTML
      const response = await fetch('/game/game.html');
      const html = await response.text();
      gameContainerRef.current.innerHTML = html;

      // Load game CSS
      const link = document.createElement('link');
      link.rel = 'stylesheet';
      link.href = '/game/css/styles.css';
      document.head.appendChild(link);



     // const oldscript = document.querySelector('script[src="/game/game.js"]');
      //if (oldscript) oldscript.remove();
      // Load game JavaScript
      const script = document.createElement('script');
      script.src = '/game/game.js';
      script.async = true;
      document.body.appendChild(script);
    };

    loadGame();

    return () => {
      // Clean up when component unmounts
      const script = document.querySelector('script[src="/game/game.js"]');
      if (script) script.remove();
      const link = document.querySelector('link[href="/game/css/styles.css"]');
      if (link) link.remove();

      // Clear the game container
    if (gameContainerRef.current) {
      gameContainerRef.current.innerHTML = '';
    }
    };
  }, []);

  //return <div ref={gameContainerRef}></div>;
  return (
    <iframe
      ref={iframeRef}
      title="Game Frame"
      style={{ width: '100%', height: '600px', border: 'none' }}
    ></iframe>
  );


};

export default GameWrapper;
