import React from "react";
import "./index.css"; // Your main CSS
import Header from "./header";
import Services from "./services";
import About from "./about";
import Customizations from "./customizations";
import Integrations from "./integrations";
import Utilities from "./utilities";
import WhyMe from "./why-me";
import Contact from "./contact";


import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import GameWrapper from "./GameWrapper";


/*function App() {

}*/

class App extends React.Component {
  componentDidMount() {
    // Define the gtag function only once
    function gtag() {
      window.dataLayer.push(arguments);
    }

    // Initialize the dataLayer array if it doesn't already exist
    window.dataLayer = window.dataLayer || [];

    // Record a page view
    gtag("js", new Date());

    // Initialize Google Analytics tracking
    gtag("config", "AW-16528448767");

    // Send a conversion event
    gtag("event", "conversion", {
      send_to: "AW-16528448767/7yqeCM7l6KUZEP-5sMk9",
      value: 1.0,
      currency: "USD",
    });
  }

  render() {
    return (
      <div>
        {/*  <nav>
            <ul>
              <li><a href="/">Home</a></li>
              <li><a href="/about">About</a></li>
              <li><a href="/Customizations">Customizations</a></li>
            </ul>
    </nav>*/}
        <Header />
        <Router>
          <Routes>
            <Route path="/about" element={<About />} />
            <Route path="/why-me" element={<WhyMe />} />
            <Route path="/customizations" element={<Customizations />} />
            <Route path="/integrations" element={<Integrations />} />
          /*  <Route path="/utilities" element={<Utilities />} />*/
            <Route path="/contact" element={<Contact />} />
            <Route path="/gamewrapper" element={<GameWrapper />} />
            <Route path="/" element={<Services />} />
          </Routes>
        </Router>
        <center><a href="/utilities"><button>Free Utilities!</button></a></center>
        <center><a href="/GameWrapper"><button>ERP Defender</button></a></center>
        <br></br>
        <About />
      </div>
    );
  }
}

export default App;
